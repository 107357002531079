<template>
<div class="map-wrapper">
    <el-autocomplete v-model="form.address" style="width:100%;" popper-class="autoAddressClass" :fetch-suggestions="querySearchAsync" :trigger-on-focus="false" placeholder="详细地址" clearable @select="handleSelect">
        <template slot-scope="{ item }">
            <i class="el-icon-search fl mgr10" />
            <div style="overflow:hidden;" class="map-place-item">
                <div class="title">{{ item.title }}</div>
                <span class="address ellipsis">{{ item.address }}</span>
            </div>
        </template>
    </el-autocomplete>
    <div id="map-container" style="width:100%;height:300px;margin-top:10px;"></div>
</div>
</template>

<script>
import loadBMap from './loadBMap.js'
import jsonpAdapter from 'axios-jsonp'
import { debounce } from 'lodash'

export default {
    /* eslint-disable */
    props: {
        // 要发送给父组件的点坐标，经纬度
        point: {
            required: false,
            default: {
                lat: '',
                lng: '',
            },
        },
        isEdit: {
            required: true,
            type: Boolean,
        },
        address: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            form: {
                address: '', //详细地址
                addrPoint: { //详细地址经纬度
                    lng: 0,
                    lat: 0,
                },
            },
            map: '', //地图实例
            mk: '', //Marker实例
        }
    },
    //这里运用async/await 进行异步处理，保证BMap加载进来后才执行后面的操作
    created() {},
    async mounted() {
        await loadBMap() // 加载引入BMap
        this.init()
    },
    methods: {
        init() {
            /* eslint-disable */
            this.form.address = this.address
            this.$nextTick(() => {
                this.initMap()
            })
        },
        // 初始化地图
        initMap() {
            let that = this
            
            // 1、挂载地图
            that.map = new BMap.Map('map-container', {
                enableMapClick: false,
            })
            let point = new BMap.Point(114.30, 30.60) //默认武汉市的经纬度
            that.map.centerAndZoom(point, 19)

            // 3、设置图像标注并绑定拖拽标注结束后事件
            this.mk = new BMap.Marker(point, {
                enableDragging: true
            })
            this.map.addOverlay(this.mk)
            this.mk.addEventListener('dragend', function (e) {
                that.getAddrByPoint(e.point)
            })

            // 4、添加（右上角）平移缩放控件
            this.map.addControl(new BMap.NavigationControl({
                anchor: BMAP_ANCHOR_TOP_RIGHT,
                type: BMAP_NAVIGATION_CONTROL_SMALL
            }))

            // 5、添加（左下角）定位控件
            // let geolocationControl = new BMap.GeolocationControl({
            //     anchor: BMAP_ANCHOR_BOTTOM_LEFT
            // })
            // geolocationControl.addEventListener('locationSuccess', function (e) {
            //     that.getAddrByPoint(e.point)
            // })
            // geolocationControl.addEventListener('locationError', function (e) {
            //     // alert(e.message)
            //     console.log(e.message)
            // })
            // this.map.addControl(geolocationControl)

            // 6、新增 浏览器定位
            this.geolocation()

            // 7、绑定点击地图任意点事件
            this.map.addEventListener('click', function (e) {
                that.getAddrByPoint(e.point)
            })

            //添加鼠标滚动缩放
            that.map.enableScrollWheelZoom();
        },
        // 浏览器定位函数
        geolocation() {
            if (!this.isEdit) {
                let that = this
                let geolocation = new BMap.Geolocation()
                geolocation.getCurrentPosition(function (res) {
                    console.log("geolocation -> res", res)
                    if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                        that.getAddrByPoint(res.point)
                    } else {
                        // alert('failed' + this.getStatus())
                        // that.$message.error('failed' + this.getStatus())
                        console.log('failed' + this.getStatus())
                    }
                }, {
                    enableHighAccuracy: true
                })
            } else {
                console.log("idEdit", this.isEdit, this.point)
                let new_point = new BMap.Point(this.point.lng, this.point.lat);
                this.getAddrByPoint(new_point)
            }

        },

        /**
         * 2、逆地址解析函数 --- 根据坐标获取具体位置
         * @public
         * @param {BMap.Point} point
         */
        getAddrByPoint:debounce(function (point) {
            let that = this
            let geco = new BMap.Geocoder()
            console.log("getAddrByPoint -> point", point)
            this.getAddress(point);
            geco.getLocation(point, function (res) {
                that.$emit("onMapAdress", res, point)
            console.log("getAddrByPoint -> res", res)
                that.mk.setPosition(point)
                that.map.panTo(point)
                that.form.address = res.address
                that.form.addrPoint = point
            })
            
        },500),
        // 根据经纬度 获取
        getAddress(point) {
            console.log("getAddress -> point", point)

            if(!point.lat || !point.lng) {
                return
            }

            const url = `https://api.map.baidu.com/reverse_geocoding/v3/?ak=uOONS3ZiGapapMDDpXdFGcYNfxHglkYx&output=json&coordtype=wgs84ll&location=${point.lat},${point.lng}`
            this.$http({
                url,
                adapter: jsonpAdapter,
            }).then((res) => {
                if (res.data.status === 0) {
                    console.log("getAddress", res.data.result)
                    this.$emit('getRegion', res.data.result.addressComponent.adcode)
                     this.$emit('getPoint', point, this.form.address)
                }
            })
        },
        // 8-1、地址搜索
        querySearchAsync(str, cb) {
            let options = {
                onSearchComplete: function (res) {
                    let s = []
                    if (local.getStatus() == BMAP_STATUS_SUCCESS) {
                        for (let i = 0; i < res.getCurrentNumPois(); i++) {
                            s.push(res.getPoi(i))
                        }
                        cb(s)
                    } else {
                        cb(s)
                    }
                }
            }
            let local = new BMap.LocalSearch(this.map, options)
            local.search(str)
        },
        // 8-2、选择地址
        handleSelect(item) {
            console.log("handleSelect -> item", item)
            this.form.address = item.address + item.title
            this.form.addrPoint = item.point
            this.map.clearOverlays()
            this.mk = new BMap.Marker(item.point)
            this.map.addOverlay(this.mk)
            this.map.panTo(item.point)

            this.getAddress(item.point)

        },
        onSubmit() {
            console.log(this.form)
        },
        clearData() {
            this.form.address = "";
            this.form.addrPoint.lng = "";
            this.form.addrPoint.lat = "";
        }
    }
}
</script>

<style lang="less">
.autoAddressClass {
    .map-place-item {
        line-height: 25px;
    }

    li {
        .el-icon-search {
            margin-top: 7px;
        }

        .mgr10 {
            margin-right: 10px;
        }

        .title {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .address {
            line-height: 1;
            font-size: 12px;
            color: #b4b4b4;
            margin-bottom: 5px;
        }
    }
}
</style>
